
export default {
  'main.title': 'ALC Test Manage',
  'home.home': 'Home',
  'user.account': 'Email',
  'user.password': 'Password',
  'user.regnow': 'Sign up now',
  'user.login': 'Sign in',
  'user.signout': 'Sign out',
  'user.captcha': 'Captcha',
  'user.signInGoogle': 'Sign in with Google',
  'user.emailCaptcha': 'Email Captcha',
  'user.getCaptcha': 'Get Captcha',
  'user.readAndAgree': 'I have read and agree the ',
  'user.UserServiceAgreement': 'User Service Agreement',
  'user.action.delete': 'Delete',
  'user.action.more': 'More actions',
  'menu.record': 'Test Record',
  'menu.driver': 'Driver Info',
  'menu.title': 'ALC Manager',
  'user.accountSetting': 'Account setting',
  'user.tip': 'Tip',
  'table.emptyText': 'Data empty',
  'user.name': 'Name',
  'user.test.video': 'video',
  'user.shareStatus': 'Share Enable',
  'user.bindTime': 'Bind Time',
  'user.action.search': 'Search',
  'user.action.bind': 'Bind Driver',
  'user.action.scanBind': 'Bind by scan qr code',
  'user.action.emailBind': 'Bind by input admin email',
  'user.visit.tip': 'Please invite the driver to scan the QR code in person',
  'user.test.value.limited': 'Upgrade to Pro or Pro+membership to view test data'

}
